.vjs-icon-placeholder {
  font-family: VideoJS !important;
}

.vjs-play-progress {
  font-family: VideoJS !important;
}

.vjs-volume-level {
  font-family: VideoJS !important;
}

.video-js .vjs-big-play-button {
  top: 45% !important;
  left: 45% !important;
}
