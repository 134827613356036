@import 'styles/breakpoints.scss';

.webinar-video-player {
  transform: translateZ(0);
}

.webinar-video-player:-webkit-full-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .webinar-video-container {
    background: black;
  }

  .webinar-video-wrapper {
    padding-bottom: 0 !important;
  }
}

.webinar-video-container {
  position: relative;
  width: 100%;
  flex: 1;
  background-color: #000;
  height: max-content;
  display: block;
  overflow: hidden;

  iframe {
    position: absolute;
    top: -70px;
    left: 0;
    width: 100%;
    height: calc(100% + 140px);
  }

  @screen sm {
    padding: 5px 0;
  }
}

.webinar-video-wrapper {
  text-align: center;
  position: relative;
  height: 100%;
  pointer-events: none;

  iframe {
    position: absolute;
  }

  @screen sm {
    margin: 0;
  }
}
