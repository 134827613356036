#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  @apply bg-red-700;

  position: fixed;
  z-index: 8000;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
