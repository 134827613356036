@import url('https://fonts.googleapis.com/css2?family=Muli:wght@200;300;400;500;600;700;800;900&display=swap');

body {
  background-color: #f8f8f8;
}

body,
body * {
  font-family: 'Muli', sans-serif !important;
}

button:focus {
  outline: none !important;
}

.Toastify {
  @apply flex justify-center fixed w-full;
  z-index: 9999;
  pointer-events: none;
}

.Toastify__toast-container {
  @apply w-auto max-w-full top-0 left-0 transform translate-x-0 mt-1 relative px-5;
}

.Toastify__toast {
  @apply rounded-full overflow-hidden font-semibold leading-tight min-h-0 text-center shadow-lg text-sm px-8 py-2;
  pointer-events: auto;

  @screen sm {
    @apply text-base px-10 py-4;
  }
}

.Toastify__toast--error {
  @apply bg-red-500;
}

.Toastify__toast--success {
  @apply bg-green-500;
}

.Toastify__toast-body {
  @apply mt-0 mb-0;
}

.Toastify__close-button {
  @apply hidden;
}

.pac-container {
  @apply font-bold shadow-lg rounded-lg transform translate-y-2;

  .pac-item {
    @apply py-1 px-3 border-0;
  }
}

.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom .gmnoprint {
  @apply overflow-hidden rounded-md shadow-lg;
}

.z-transform {
  transform: translateZ(0);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
}

.pattern-background {
  background-image: url('/static/images/pattern.png');
  background-repeat: repeat;
  background-position: center;
}

.pattern-background-overlay {
  background-image: url('/static/images/pattern-darker.png');
  background-repeat: repeat;
  background-position: center;
  background-blend-mode: multiply;
}

@keyframes fancy-bounce-transform {
  0% {
    transform: scale(0.6);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes fancy-bounce-opacity {
  0% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

.fancy-bounce {
  animation: fancy-bounce-transform 0.2s cubic-bezier(0.18, 1.4, 0.4, 1) forwards,
    fancy-bounce-opacity 0.15s ease-out forwards;
}

.image-shadow {
  filter: drop-shadow(0 17px 36px rgba(0, 0, 0, 0.2));
}

.slick-slide .slide-show-active {
  @apply border-2 border-transparent;
}

.slick-slide.slick-current .slide-show-active {
  @apply border-2 border-orange-500;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Rich text styles */
.rich {
  @apply text-gray-700;
}

.rich h1 {
  @apply text-3xl font-bold border-b mb-8;
}

.rich h2 {
  @apply text-xl font-bold;
}

.rich h3 {
  @apply text-lg font-bold;
}

.rich a {
  @apply text-orange-500;

  &:hover {
    @apply underline;
  }
}

.rich ol {
  @apply list-decimal;
}

.rich ul {
  list-style: inherit;
}

.rich ol,
.rich ul {
  @apply pl-5;
}

.rich p {
  @apply mb-5;
}

/* Carousel */
.carousel-dots {
  @apply absolute z-20 mb-5 w-full text-center pointer-events-none;

  li {
    @apply inline-block opacity-50 pointer-events-auto;
  }

  :global(li.slick-active) {
    @apply opacity-100;
  }
}

/* Challenges table */
.challenge-table {
  @apply rounded-lg bg-gray-400 text-white overflow-hidden;

  td,
  th {
    @apply px-3 py-4 text-white;
  }

  ul {
    @apply list-disc ml-4;
  }
}

/* Layout */
.screen-height {
  min-height: calc(100vh - theme('height.12'));

  @screen md {
    min-height: calc(100vh - theme('height.16'));
  }
}

.force-screen-height {
  height: calc(100vh - theme('height.12'));

  @screen md {
    height: calc(100vh - theme('height.16'));
  }
}

/* Companies Grid */
.grid-auto-rows-companies {
  grid-auto-rows: 14rem;
}

/* Links */
.links a {
  @apply text-orange-500;

  &:hover {
    @apply underline;
  }
}

.linkified {
  @apply text-orange-500;

  &:hover {
    @apply underline;
  }
}

/* Wrap texts */
.wrap {
  @apply break-words;
  overflow-wrap: anywhere;
}

/* Blur */
.white-blur {
  @apply bg-white bg-opacity-100;
}

@supports ((-webkit-backdrop-filter: blur(15px)) or (backdrop-filter: blur(15px))) {
  .white-blur {
    @apply bg-white bg-opacity-75;
    -webkit-backdrop-filter: blur(2em);
    backdrop-filter: blur(2em);
  }
}

/* Safe Area insets */
.bottom-safe-area-padding {
  padding-bottom: env(safe-area-inset-bottom);
}

/* Safari compatible overflow-hidden */
.overflow-hidden-z {
  @apply overflow-hidden;
  transform: translateZ(0);
}

.react-select__menu {
  position: relative;
  z-index: 9999 !important;
}

/* Used in the body when the chat is open */
.overflow-hidden-chat {
  @apply overflow-hidden;
  @screen sm {
    @apply overflow-auto;
  }
}

/** Post detail image **/
.post-image {
  height: auto;
}

.post-detail-with-image {
  @screen lg {
    height: calc(100vh - 80px);
  }
}

.post-image img {
  pointer-events: none;
}

.post-image .background,
.blur-image {
  filter: blur(10px);
  transform: scale(1.2);
}

/** Youtube iframe **/
.youtube-iframe-container {
  @apply relative w-full h-0;
  padding-bottom: 56.25%;

  iframe {
    @apply absolute top-0 left-0 w-full h-full;
  }
}

/** Vimeo iframe **/
.vimeo-iframe-container {
  @apply relative w-full h-0;
  padding-bottom: 56.25%;

  iframe {
    @apply absolute top-0 left-0 w-full h-full;
  }
}

/* https://codepen.io/sosuke/pen/Pjoqqp */
/* SVG FILTER ORANGE-500 */
.svg-orange-500 {
  filter: invert(60%) sepia(61%) saturate(914%) hue-rotate(344deg) brightness(101%) contrast(101%);
}

/* SVG FILTER GRAY-700 */
.svg-gray-700 {
  filter: invert(32%) sepia(10%) saturate(978%) hue-rotate(180deg) brightness(97%) contrast(92%);
}

/* Add to calendar button */

.react-add-to-calendar {
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  position: relative;
  display: inline-block;
  margin: 0 auto;

  &__wrapper {
    zoom: 1;
    cursor: pointer;
    width: max-content !important;
  }

  &__button {
    //bg-gray-300 hover:bg-gray-200 hover:bg-opacity-75 rounded-lg
    padding: 0.75rem;

    --bg-opacity: 1;
    background-color: rgba(255, 147, 30, var(--bg-opacity)) !important;

    border-style: solid !important;
    border-color: #e2e8f0 !important;
    border-radius: 0.5rem !important;

    --text-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(255, 255, 255, var(--text-opacity)) !important;
    font-size: 0.875rem !important;
    line-height: 1rem !important;

    &--light {
      --bg-opacity: 1 !important;
      background-color: rgba(255, 147, 30, var(--bg-opacity)) !important;
    }
  }

  &__button:hover {
    // hover:bg-gray-200 hover:bg-opacity-75
    --bg-opacity: 0.75 !important;
    background-color: rgba(255, 147, 30, var(--bg-opacity)) !important;
  }

  &__icon {
    &--right {
      padding-left: 5px;
    }

    &--left {
      padding-right: 5px;
    }
  }

  &__dropdown {
    position: absolute;
    z-index: 30;
    top: 34px !important;
    left: 1px;
    width: 93%;
    padding: 5px 0 5px 0px !important;

    margin-top: -271px;

    border-style: solid !important;
    border-color: #e2e8f0 !important;
    border-radius: 0.5rem !important;

    text-align: left;
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity)) !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;

    --tw-bg-opacity: 0.75;
    background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;

    ul {
      list-style: none;
      margin: 0;

      li {
        display: flex !important;
        padding: 0.5rem 0.5rem 0.5rem 0.8rem !important;
        a {
          color: rgba(74, 85, 104, var(--text-opacity)) !important;
          flex-grow: 1 !important;
          i {
            padding-right: 10px;
          }
        }
      }

      li:hover {
        --bg-opacity: 1;
        background-color: #e2e8f0 !important;
        background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
      }
    }

    // OTHERS BUTTON ON SHARE CALENDAR ICON
    ul li:nth-child(6) {
      .fa-windows:before {
        font: normal normal normal 14px/1 FontAwesome;
        content: '\f073' !important;
      }
    }
  }
}

.react-add-to-calendar-shadow {
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  position: relative;
  display: inline-block;
  margin: 0 auto;

  &__wrapper {
    zoom: 1;
    cursor: pointer;
    width: max-content !important;
  }

  &__button {
    //bg-gray-300 hover:bg-gray-200 hover:bg-opacity-75 rounded-lg

    --bg-opacity: 1;
    background-color: transparent !important;

    border-style: solid !important;
    border-color: transparent !important;
    border-radius: 0.5rem !important;

    --text-opacity: 1 !important;
    color: #ff931e;
    color: rgba(255, 147, 30, var(--text-opacity));
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    text-decoration: underline;

    &--light {
      --bg-opacity: 1 !important;
      background-color: transparent !important;
    }
  }

  &__icon {
    &--right {
      padding-left: 5px;
    }

    &--left {
      padding-right: 5px;
    }
  }

  &__dropdown {
    position: absolute;
    z-index: 30;
    top: 30px !important;
    left: 1px;
    width: 93%;
    padding: 5px 0 5px 0px !important;

    margin-top: -265px;

    border-style: solid !important;
    border-color: #e2e8f0 !important;
    border-radius: 0.5rem !important;

    text-align: left;
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity)) !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;

    --tw-bg-opacity: 0.75;
    background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;

    ul {
      list-style: none;
      margin: 0;

      li {
        display: flex !important;
        padding: 0.5rem 0.5rem 0.5rem 0.8rem !important;
        a {
          color: rgba(74, 85, 104, var(--text-opacity)) !important;
          flex-grow: 1 !important;
          i {
            padding-right: 10px;
          }
        }
      }

      li:hover {
        --bg-opacity: 1;
        background-color: #e2e8f0 !important;
        background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
      }
    }

    // OTHERS BUTTON ON SHARE CALENDAR ICON
    ul li:nth-child(6) {
      .fa-windows:before {
        font: normal normal normal 14px/1 FontAwesome;
        content: '\f073' !important;
      }
    }
  }
}

.fa-google:before {
  font: normal normal normal 14px/1 FontAwesome;
  content: '\f1a0' !important;
}

.fa-apple:before {
  font: normal normal normal 14px/1 FontAwesome;
  content: '\f179' !important;
}

.fa-yahoo:before {
  font: normal normal normal 14px/1 FontAwesome;
  content: '\f19e' !important;
}

.fa-windows:before {
  font: normal normal normal 14px/1 FontAwesome;
  content: '\f17a' !important;
}

.fa-calendar-plus-o:before {
  font: normal normal normal 14px/1 FontAwesome;
  content: '\f271' !important;
}

.fa-calendar-o:before {
  font: normal normal normal 14px/1 FontAwesome;
  content: '\f073' !important;
}

.linkedin-bg {
  background: #0e76a8;
}

/* Notification Tabs */
.notification-tab--selected {
  display: inline-block;

  --tw-border-opacity: 1 !important;

  bottom: -3px !important;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.notification-tab-panel--selected {
  display: block !important;
}

.react-tabs {
  &__tab-list {
    border-bottom: none;
    margin: 0 0 10px;
    padding: 0;
    --tw-bg-opacity: 1;
    background-color: rgba(160, 174, 192, var(--bg-opacity));
  }
  &__tab[aria-selected='false'] {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    font-weight: 700;
  }
}

/* Articles */
.article-body {
  p {
    margin-bottom: 1rem;
  }

  figure {
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1.25rem;
  }
  h1 {
    font-size: 1.5rem;
  }
  p {
    font-size: 1.0625rem;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  a {
    color: #ff931e;
    text-decoration: underline;
  }
}

.custom-calendar-button .react-add-to-calendar {
  @apply w-full h-full;
}

.custom-calendar-button .react-add-to-calendar .react-add-to-calendar__wrapper {
  @apply w-full h-full flex;
}

.custom-calendar-button .react-add-to-calendar .react-add-to-calendar__dropdown {
  @apply w-full;
}

.landingItem:nth-child(even) {
  flex-direction: row-reverse;
}
.landingItem:nth-child(even) .landingText {
  align-items: end;
  text-align: right;
}

@media (max-width: 640px) {
  .landingItem:nth-child(even) {
    flex-direction: column;
  }
  .landingItem .landingText:nth-child(even),
  .landingItem .landingText:nth-child(odd) {
    align-items: center;
    text-align: center;
  }
}

// Fade animation for the ads banner
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.big-banner {
  width: 449px;
  height: 373px;
  @media (min-width: 1280px) {
    width: 320px;
    height: 266px;
  }
}
