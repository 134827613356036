/* Multiline */
.content-input.content-input--multiLine {
  @apply w-full  min-w-0 max-w-full;
}

.content-input.content-input--multiLine .content-input__input {
  @apply w-full text-base resize-none leading-tight z-10 leading-tight py-2;
  word-break: break-word;
}

.content-input.content-input--multiLine .content-input__input:focus {
  @apply outline-none;
}

.content-input.content-input--multiLine .content-input__control {
  @apply w-full;
  font-family: monospace;
  font-size: 16px;
}

.content-input.content-input--multiLine .content-input__highlighter {
  @apply border-0 z-20 text-base pointer-events-none leading-tight py-2;
  border: none !important;
  word-break: break-word;
}

/* Single line */
.content-input.content-input--singleLine {
  @apply w-full min-w-0 max-w-full;
}

.content-input.content-input--singleLine .content-input__input {
  @apply text-base leading-tight z-10 leading-tight py-2;
}

.content-input.content-input--singleLine .content-input__input:focus {
  @apply outline-none;
}

.content-input.content-input--singleLine .content-input__control {
  @apply w-full;
  font-family: monospace;
  font-size: 16px;
  word-break: break-all;
}

.content-input.content-input--singleLine .content-input__highlighter {
  @apply border-0 z-20 text-base pointer-events-none leading-tight py-2;
  border: none !important;
}

/* General */
.content-input .content-input__highlighter strong {
  @apply text-orange-500;
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff,
    -1px 1px 0 #fff;
}

.content-input.content-input--multiLine,
.content-input.content-input--singleLine {
  @apply w-full;
}

.content-input .content-input__suggestions {
  @apply bg-white rounded-lg shadow-lg text-sm overflow-hidden;
  z-index: 50 !important;
}

.content-input__suggestions__list {
}

.content-input__suggestions__item {
  @apply border-b border-gray-200;
}

.content-input__suggestions__item:last-child {
  @apply border-0;
}

.content-input__suggestions__item--focused {
  @apply bg-gray-100;
}

.content-input__mention {
  position: relative;
  z-index: 1;
  color: blue;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white;
  text-decoration: underline;
  pointer-events: none;
}

@keyframes stretchdelay {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.content-input .content-input__suggestions__loadingIndicator__spinner {
  margin-top: 4px;
  margin-bottom: 4px;

  width: 100px;
  height: 8px;

  text-align: center;
  font-size: 11px;
}

.content-input .content-input__suggestions__loadingIndicator__spinner__element {
  display: inline-block;
  background-color: #999;
  height: 100%;
  width: 2px;
  margin-left: 3px;
  margin-right: 3px;
  animation: x 1.2s infinite ease-in-out;
  animation-name: stretchdelay;
}

.comment .content-input .content-input__input,
.comment .content-input .content-input__highlighter {
  @apply px-4;
}
