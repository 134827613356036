.swiper-button-prev {
  font-family: pronto-icons !important;
  speak: never;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  @apply bg-white rounded-full w-10 h-10 p-0 my-auto top-0 bottom-0 text-white left-0 ml-2 pointer-events-auto !important;
}

.swiper-button-prev:after {
  @apply text-sm font-extrabold text-gray-700 !important;
}

.swiper-button-next {
  font-family: pronto-icons !important;
  speak: never;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  @apply bg-white my-auto w-10 h-10 top-0 bottom-0 rounded-full p-0 text-white right-0 mr-2 pointer-events-auto !important;
}

.companySlider > .swiper-button-next {
  @apply h-full bg-transparent rounded-none mr-0 !important;
  background-color: #f8f8f8 !important;
}
.companySlider > .swiper-button-prev {
  @apply h-full rounded-none ml-0 !important;
  background-color: #f8f8f8 !important;
}

.companySlider > .swiper-button-next:hover {
  background-color: #f8f8f8 !important;
}
.companySlider > .swiper-button-prev:hover {
  background-color: #f8f8f8 !important;
}

.companySlider > .swiper-button-disabled {
  @apply hidden !important;
}
.companySliderItem {
  @apply w-12 !important;
  transition: transform 0.3s;
}

.companySliderItem:hover {
  transform: scale(1.1);
}

@media (min-width: 640px) {
  .companySliderItem {
    @apply w-16 !important;
  }
}

.swiper-button-next:after {
  @apply text-sm font-extrabold text-gray-700 !important;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  @apply bg-gray-200 !important;
}
